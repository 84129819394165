import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import classNames from "classnames";
import empty from "is-empty";
import { store } from "react-notifications-component";
import {
  Button,
  Message,
  Placeholder,
  Modal,
  Form,
  Input,
} from "semantic-ui-react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { withCookies } from "react-cookie";
import { login, logout } from "../actions/user";
import { setMessages } from "../actions/message";
import { setConversations } from "../actions/conversation";
import { groupConversations } from "../common/utils";
import Base from "./Base";
import Sidebar from "./Sidebar";
import Toolbar from "./Toolbar";
import Conversation from "./Conversation";
import "../styles/MessagesPage.scss";
import { bindActionCreators } from "redux";

class MessagesPage extends Base {
  constructor(props) {
    super(props);
    this.getConversations = this.getConversations.bind(this);
    this.getAccountPhoneNumbers = this.getAccountPhoneNumbers.bind(this);
    this.generateConversationMapping =
      this.generateConversationMapping.bind(this);
    this.buyPhoneNumber = this.buyPhoneNumber.bind(this);
    this.buyTollFreeNumber = this.buyTollFreeNumber.bind(this);
    this.deletePhoneNumber = this.deletePhoneNumber.bind(this);
    this.getActiveRecipientPhoneNumber =
      this.getActiveRecipientPhoneNumber.bind(this);
    this.getActiveConversationAgentPhoneNumber =
      this.getActiveConversationAgentPhoneNumber.bind(this);
    this.removeLostConversations = this.removeLostConversations.bind(this);

    // Event source handles
    this.smsMessagesEventSource = null;
    this.smsConversationsEventSource = null;
    this.phoneListEventSource = null;

    // Create a way to access this React component methods
    window.ReactAppBridge = this;

    this.state = {
      accountPhoneNumbers: [],
      conversationMap: [],
      loginFormEmail: "",
      loginFormPassword: "",
      phoneNumberSearch: "",
      isLoginModalOpen: false,
      isLoggingIn: false,
    };
  }

  async componentDidMount() {
    if (!empty(this.props.user)) {
      this.getConversations();
      this.getAccountPhoneNumbers();
    }
  }

  componentDidUpdate() {
    if (empty(this.props.user)) {
      // Detect if user logged out
      if (!empty(this.smsConversationsEventSource)) {
        console.log("Disconnecting from SMS Conversation EventSource");
        this.smsConversationsEventSource.close();
        this.smsConversationsEventSource = null;
        if (!empty(this.props.converesations)) {
          this.props.setConversations([]);
        }
      }

      if (!empty(this.phoneListEventSource)) {
        console.log("Disconnecting from Account Phone Numbers EventSource");
        this.phoneListEventSource.close();
        this.phoneListEventSource = null;
        if (!empty(this.state.accountPhoneNumbers)) {
          this.setState({ accountPhoneNumbers: [] });
        }
      }
    } else {
      if (empty(this.smsConversationsEventSource)) {
        this.getConversations();
      }

      if (empty(this.phoneListEventSource)) {
        this.getAccountPhoneNumbers();
      }
    }
  }

  async getConversations() {
    const endpoint = `${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/sms/conversations`;
    this.smsConversationsEventSource = new EventSource(endpoint, {
      withCredentials: true,
    });

    this.smsConversationsEventSource.addEventListener(
      "activeConversations",
      (event) => {
        try {
          const data = JSON.parse(event.data);
          let conversations = _.get(data, "conversations");
          conversations = _.concat(conversations, this.props.conversations);
          conversations = _.uniqBy(conversations, "id");
          conversations = _.sortBy(conversations, (conversation) => {
            return conversation.last_message_time;
          }).reverse();

          // messages = this.removeLostConversations(messages);
          this.props.setConversations(conversations);
        } catch (e) {}
      }
    );
  }

  /**
   * Get all phone numbers associated to this account
   */
  async getAccountPhoneNumbers() {
    const endpoint = `${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/phone/list`;
    this.phoneListEventSource = new EventSource(endpoint, {
      withCredentials: true,
    });

    this.phoneListEventSource.addEventListener("phoneList", (event) => {
      try {
        const data = JSON.parse(event.data);
        const phoneList = _.get(data, "phone_list");

        if (!empty(phoneList)) {
          this.setState({
            accountPhoneNumbers: phoneList.map((row) => row.phoneNumber),
          });
        }
      } catch (e) {}
    });
  }

  /**
   * Retrieve the phone number of the recipient of the open conversation
   * @return {string} phoneNumber
   */
  getActiveRecipientPhoneNumber(conversationId) {
    if (empty(this.props.activeConversationId) && empty(conversationId)) {
      return "";
    }

    const conversation = this.props.conversations.find(
      (conversation) =>
        conversation.id == conversationId ||
        conversation.id == this.props.activeConversationId
    );
    return conversation.direction == "inbound"
      ? conversation.from
      : conversation.to;
  }

  /**
   * Retrieve the agent phone number of the open conversation
   * @param {string} conversationId
   */
  getActiveConversationAgentPhoneNumber(conversationId) {
    if (empty(this.props.activeConversationId) && empty(conversationId)) {
      return "";
    }

    const conversation = this.props.conversations.find(
      (conversation) =>
        conversation.id == conversationId ||
        conversation.id == this.props.activeConversationId
    );
    return conversation.direction == "inbound"
      ? conversation.to
      : conversation.from;
  }

  /**
   * Remove conversations that are only outgoing with no
   * response from the lead. This is a considered a lost lead
   * until the lead actually responds.
   * @param {array} msgs
   */
  removeLostConversations(msgs) {
    let inboundBucket = _.uniq(
      msgs
        .filter((msg) => msg.direction == "inbound")
        .map((msg) => msg.conversation_id)
    );
    let outboundBucket = _.uniq(
      msgs
        .filter((msg) => msg.direction == "outbound")
        .map((msg) => msg.conversation_id)
    );

    return msgs.filter((msg) => {
      if (
        _.indexOf(outboundBucket, msg.conversation_id) !== -1 &&
        _.indexOf(inboundBucket, msg.conversation_id) === -1
      ) {
        return false;
      }

      return true;
    });
  }

  /**
   * Delete phone number from Twilio account
   * @param {string} phoneNumber
   */
  deletePhoneNumber(phoneNumber) {
    axios
      .delete(
        `${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/phone/${phoneNumber}`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          store.addNotification({
            title: "Success",
            message: `${phoneNumber} phone number was deleted successfully`,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      })
      .catch((err) => {
        store.addNotification({
          title: "Message",
          message: err.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
  }

  /**
   * Send a text message using one of the account phone numbers
   * @param {string} fromPhone
   * @param {string} toPhone
   * @param {string} message
   */
  sendSmsMessage(fromPhone, toPhone, message) {
    if (this.state.accountPhoneNumbers.indexOf(fromPhone) === -1) {
      alert("The from phone number is not in the account");
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/sms/send`,
        {
          fromPhone,
          toPhone,
          message,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          this.props.setMessages([response.data, ...this.props.messages]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /**
   * Marks a message as read
   * @param {string} messageSid
   *  - A Twilio-provided string that uniquely identifies the Message resource to update.
   */
  async markMessageAsRead(messageSid) {
    const response = await axios
      .post(
        `${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/sms/mark-as-read`,
        {
          message_sid: messageSid,
        },
        {
          withCredentials: true,
        }
      )
      .catch((err) => console.log(err));

    if (response.status === 200) {
      // Update message status in state
      const matchIndex = this.props.messages.findIndex((message) => {
        return message.sid === messageSid;
      });

      const messagesCopy = this.props.messages;
      messagesCopy[matchIndex] = {
        ...messagesCopy[matchIndex],
        status: "read",
      };

      this.props.setMessages(messagesCopy);
    }
  }

  /**
   * Purchases phone number from Twilio
   * @param {string} area - First three digits for an area code
   */
  buyPhoneNumber(area) {
    axios
      .post(
        `${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/phone/buy`,
        {
          phone_area_code: area,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.status === 201) {
          store.addNotification({
            title: "Success",
            message: `${response.data.phoneNumber} phone number was purchased`,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      })
      .catch((err) => {
        store.addNotification({
          title: "Error!",
          message: err.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
  }

  /**
   * Purchases toll free phone number from Twilio
   */
  buyTollFreeNumber() {
    axios
      .post(
        `${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/phone/buy-toll-free`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.status === 201) {
          store.addNotification({
            title: "Success",
            message: `${response.data.phoneNumber} phone number was purchased`,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      })
      .catch((err) => {
        store.addNotification({
          title: "Error!",
          message: err.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
  }

  /**
   * Sort messages by descending date sent order
   * @param {array} msgs
   */
  sortMessages(msgs) {
    msgs.sort((a, b) => {
      if (moment(a.dtc, moment.ISO_8601) > moment(b.dtc, moment.ISO_8601)) {
        return -1;
      }

      if (moment(a.dtc, moment.ISO_8601) < moment(b.dtc, moment.ISO_8601)) {
        return 1;
      }

      return 0;
    });

    return msgs;
  }

  /**
   * Group messages into conversations where the from and to are the same
   * @param {array} msgs - Array objects of messages
   */
  generateConversationMapping(msgs) {
    let convMap = this.state.conversationMap;

    msgs.forEach((msg, index) => {
      const match = convMap.find((group) => {
        return group.hasOwnProperty(msg.from + msg.to);
      });

      if (match) {
        msg["conversationId"] = match[`${msg.from}${msg.to}`];
      } else {
        let conversationId = uuidv4();
        convMap.push(
          {
            [`${msg.from}${msg.to}`]: conversationId,
          },
          {
            [`${msg.to}${msg.from}`]: conversationId,
          }
        );

        msg["conversationId"] = conversationId;
      }

      msgs[index] = msg;
    });

    this.setState({
      conversationMap: convMap,
    });

    return msgs;
  }

  render() {
    return (
      <div className="dashboard-page">
        <Sidebar>
          <div className="sidebar__heading">
            <h2>Messages</h2>
          </div>

          <ul className="sms-message-list">
            {this.props.conversations.length === 0 && !empty(this.props.user) && (
              <span>
                <li>
                  <Placeholder fluid>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                </li>
                <li>
                  <Placeholder fluid>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                </li>
                <li>
                  <Placeholder fluid>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                </li>
                <li>
                  <Placeholder fluid>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                </li>
                <li>
                  <Placeholder fluid>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                </li>
              </span>
            )}

            {this.props.conversations
              .filter((conversation) => {
                if (!empty(this.state.phoneNumberSearch)) {
                  if (conversation.direction === "inbound") {
                    return conversation.from.startsWith(
                      this.state.phoneNumberSearch
                    );
                  } else {
                    return conversation.to.startsWith(
                      this.state.phoneNumberSearch
                    );
                  }
                }

                return true;
              })
              .map((conversation, idx) => {
                return (
                  <li
                    key={idx}
                    className={classNames({
                      unread: conversation.read_status == "unread",
                      read: conversation.read_status == "read",
                      active:
                        conversation.id === this.props.activeConversationId,
                    })}
                    onClick={async (e) => {
                      this.props.setActiveConversationId(conversation.id);
                    }}
                  >
                    <p className="phone-number">
                      {conversation.direction === "inbound"
                        ? conversation.from
                        : conversation.to}
                    </p>
                  </li>
                );
              })}
          </ul>
        </Sidebar>

        <main>
          {!empty(this.props.user) ? (
            <Toolbar>
              <div className="left-side">
                <h3>{this.getActiveConversationAgentPhoneNumber()}</h3>
                {this.props.activeConversationId && (
                  <Button
                    basic
                    onClick={async () => {
                      const activeRecipientPhoneNumber =
                        this.getActiveRecipientPhoneNumber(
                          this.props.activeConversationId
                        );

                      if (!empty(activeRecipientPhoneNumber)) {
                        await this.sendFilemakerScript(
                          "Database.fmp12",
                          "SMS_Dashboard_Turbo_ScrapePhoneNumber",
                          activeRecipientPhoneNumber
                        ).catch(() => {
                          console.log(
                            "Filemaker protocol is not detected in the current system."
                          );
                        });
                      }
                    }}
                  >
                    Sync
                  </Button>
                )}
              </div>

              <div className="right-side">
                <div className="ui input phone-number-search-filter">
                  <MaskedInput
                    mask={createNumberMask({
                      prefix: "+1",
                      includeThousandsSeparator: false,
                    })}
                    placeholder="Filter by Phone #"
                    maxLength="12"
                    value={this.state.phoneNumberSearch}
                    onChange={(e) => {
                      this.setState({
                        phoneNumberSearch: e.target.value,
                      });
                    }}
                  />
                </div>

                {this.props.user.type === "admin" && (
                  <Link to="/users">
                    <Button secondary>Manage Users</Button>
                  </Link>
                )}

                <Button
                  basic
                  onClick={() => {
                    this.props.logout();
                    this.props.setActiveConversationId(null);
                    this.props.setConversations([]);
                  }}
                >
                  Logout
                </Button>
              </div>
            </Toolbar>
          ) : (
            <Toolbar>
              <span></span>
              <Button
                basic
                onClick={() => {
                  this.setState({
                    isLoginModalOpen: true,
                  });
                }}
              >
                Login
              </Button>
            </Toolbar>
          )}

          <Conversation
            conversationId={this.props.activeConversationId || ""}
          />
        </main>

        <Modal open={this.state.isLoginModalOpen} size="tiny">
          <Modal.Header>Login</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>Email</label>
                <input
                  type="text"
                  value={this.state.loginFormEmail}
                  onChange={(e) => {
                    this.setState({
                      loginFormEmail: e.target.value,
                    });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Password</label>
                <input
                  type="password"
                  value={this.state.loginFormPassword}
                  onChange={(e) => {
                    this.setState({
                      loginFormPassword: e.target.value,
                    });
                  }}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              secondary
              onClick={() => {
                this.props
                  .login(
                    this.state.loginFormEmail,
                    this.state.loginFormPassword
                  )
                  .then(() => {
                    this.setState({
                      isLoginModalOpen: false,
                    });
                  })
                  .catch((err) => {
                    store.addNotification({
                      title: "Error!",
                      message: `The login credentials you entered did not match our records`,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                      },
                    });
                  });
              }}
            >
              Submit
            </Button>
            <Button onClick={() => this.setState({ isLoginModalOpen: false })}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    messages: state.messages,
    conversations: state.conversations,
    activeConversationId: state.activeConversationId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      login: login,
      logout: logout,
      setMessages: setMessages,
      setConversations: setConversations,
      setActiveConversationId: (conversationId) =>
        dispatch({
          type: "SET_ACTIVE_CONVERSATION_ID",
          payload: conversationId,
        }),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(MessagesPage));
